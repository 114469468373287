/**
 * Created by Administrator on 2017/2/23.
 */
+(function ($) {
    "use strict";
    var defaults = {
        refresh:false //表单提交成功后是否刷新主页面
    };
    $.fn.modal1 = function (options) {
        var opts = $.extend({},defaults, options );
        var indexId = '#'+opts.targetId;
        var headers = {'X-Mjax-Request':'X-Mjax-Redirect'};
        var modal =
            '<div id="' + opts.targetId + '" class="fade modal" role="dialog" tabindex="-1" style="display: none;">'
                + '<div class="modal-dialog modal-lg">'
                    + '<div class="modal-content">'
                        +'<div class="modal-header">'
                        +'<button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>'
                        +'</div>'
                        +'<div class="modal-body" >'
                        +'</div>'
                        +'<div class="modal-footer">'
                        +'<a href="#" class="close btn btn-primary" data-dismiss="modal">关闭</a>'
                        +'</div>'
                    +'</div>'
                + '</div>'
            + '</div>';
        var $modal;
        var methods = {
            hasModal: false,
            renderModal: function ($contents) {
                if (!this.hasModal) {
                    $modal = this.parseDom(modal);
                    $($modal).find('.modal-body').html($contents);
                    $('body').append($modal);
                    this.hasModal = true;
                } else {
                    $(indexId + ' .modal-body').html($contents)
                }
            },
            //请求列表页
            ajaxIndex: function (route,data) {
                $(indexId).LoadingOverlay("show")
                $.ajax({
                    url: route,
                    data: data,
                    type: 'get',
                    headers:headers,
                    complete:function (xhr,textStatus) {
                        //X-Mjax-Redirect 309
                        if (xhr.status == 309) {
                            var redirect = xhr.getResponseHeader('X-Mjax-Redirect');
                            $.ajax({
                                url: redirect,
                                data: {},
                                type: 'get',
                                headers: headers,
                                success: function (res) {
                                    if (opts.refresh) {
                                        alert('操作成功')
                                        window.location.reload();
                                    }
                                    //把视图放入模态框
                                    methods.renderModal(res);
                                }
                            })
                        }
                        $(indexId).LoadingOverlay("hide")
                    },
                    success: function (res) {
                        //把视图放入模态框
                        methods.renderModal(res);
                        $(indexId).modal();

                    }
                    // error: function(xhr){
                    //     methods.renderModal(xhr.status+' '+xhr.statusText);
                    // }
                })
            },
            parseDom: function (arg) {
                var objE = document.createElement("div");
                objE.innerHTML = arg;
                return objE.childNodes;
            }
        };
        //初始化modal
        methods.renderModal('');
        $(document).on('click', '[modal-target="' + indexId + '"]', function (e) {
            var url = $(this).attr('href');

            methods.ajaxIndex(url,$(this).serialize());
            e.preventDefault();
        });
        $(indexId).on('submit','form',function (e) {
            e.preventDefault();
            var _form = e.target;
            var _thisData = $(_form).data('yiiActiveForm');
            $(indexId).LoadingOverlay("show")
            $(_form).ajaxSubmit({
                headers:headers,
                complete:function (xhr) {
                    //X-Mjax-Redirect 309
                    if(xhr.status == 309) {
                        var redirect = xhr.getResponseHeader('X-Mjax-Redirect');
                        $.ajax({
                            url: redirect,
                            data: {},
                            type: 'get',
                            headers:headers,
                            success: function (res) {
                                if (opts.refresh){
                                    alert('操作成功')
                                    window.location.reload();
                                }
                                //把视图放入模态框
                                methods.renderModal(res);
                            }
                        })
                    }
                    $(indexId).LoadingOverlay("hide")
                },
                success: function (response) {
                    //将表单的结果页面覆盖模态框Body
                    methods.renderModal(response);
                },
                error: function(xhr){
                    if (xhr.status !=309)
                    methods.renderModal(xhr.status+' '+xhr.statusText);
                }
            });

            // $(indexId).modal();
        });
        $(indexId).on('hide.bs.modal', function (e) {
            $(indexId + ' .modal-body').empty()
            if (opts.refresh) {
                window.location.reload();
            }
        });
    }
})(jQuery);