/**
 * Created by Administrator on 2017/3/4.
 */


+function ($) {
    'use strict';
    $.fn.select1andchain = function (options) {
        var opts = $.extend({}, $.fn.select1andchain.default, options);
        return this.each(function () {
            var _this = $(this);
            var _value = _this.find('[vid="' + opts.valId+'"]:first');
            var _name = _this.find('[nid="' + opts.namId+'"]:first');
            var _text = _this.find('span').text(opts.prompt);
            var _list = _this.find('ul').css({
                maxHeight: opts.maxHeight,
                overflowY: 'auto'
            });
            var _searchBox = $('<li class="text-center"></li>')
                .on('clean', function (e) {
                    _list.find('li:not(:first-child)').remove();

                });
            //延迟一秒触发update事件，连续触发则清空上一次的定时任务
            // ，防止英文输入过多的发送请求
            var timeout = 1e3; // 1 second default timeout
            var timeoutReference = null;

            var cpLock = false;
            var _search = $('<input style="width:80%;margin-left: 10px;height: 80%" type="text" value="" class="form-control" />')
                .on('compositionstart', function () {
                    cpLock = true;
                })
                .on('compositionend', function () {
                    cpLock = false;
                    _this.trigger('update');
                })
                .on('input', function (e) {
                    if (!cpLock) {
                        if (timeoutReference) clearTimeout(timeoutReference);
                        timeoutReference = setTimeout(function(){
                            _this.trigger('update');
                        }, timeout);

                    }

                });
            _searchBox.append(_search);
            _list.append(_searchBox);
            _this.on('changeNext', function () {
                var next = $(opts.nextSelector);
                if (next) {
                    next.data(_this.attr('id'), _this.find('input').val());
                    next.data('term1Name', _this.attr('id'));
                    next.data('term1Value', _this.find('input').val());
                    next.trigger('update');
                }
            });
            _this.on('update', function (e) {
                var params = {};
                params['field'] = opts.fieldName;
                params['defaultValue'] = _value.val();
                params['term'] = _search.val();
                params['term1'] = _this.data('term1Name');
                params['term1Val'] = _this.data('term1Value');
                params['ExtraName'] = opts.ExtraName;
                params['ExtraValue'] = opts.ExtraValue;

                $.getJSON(
                    opts.url,
                    params,
                    function (data) {
                        var val = opts.defaultValue?opts.defaultValue:_value.val();
                        _searchBox.trigger('clean');
                        $.each(data, function (idx, elem) {
                            _list.append('<li class="divider"></li>');
                            var option = $('<li>');
                            if (elem.id == val) {
                                option.addClass('active');
                                _name.val(elem.text);
                                _text.html(elem.text.trim());
                            }
                            var a = $('<a href="#">');
                            a.attr("data-val", elem.id)
                                .html(elem.text)
                                .click(function (e) {
                                    e.preventDefault();
                                    var _aThis = $(e.target);
                                    _value.val(_aThis.data("val"));
                                    _name.val(_aThis.text());
                                    _text.html(_aThis.text().trim());
                                    //改变下一个插件的值
                                    _this.trigger('changeNext');
                                    _value.trigger('change');

                                });
                            option.append(a);
                            _list.append(option);

                        });

                    }
                );
                _this.trigger('changeNext');

            });
            _this.on('show.bs.dropdown', function () {
                var val = _value.val();
                _list.find('a').each(function () {
                    var a = $(this);
                    if (a.data('val') == val) {
                        a.parent().addClass('active');
                    } else {
                        a.parent().removeClass('active');
                    }
                });
            });

            _this.trigger('update');

        });
    };

    $.fn.select1andchain.default = {
        fieldName: 'adminId',
        maxHeight: '300px',
        valId: '保存控件value的element Id',
        namId: '保存控件name的element Id',
        url: '',
        prompt: '请选择',
        //-------------查询的时候手动额外增加的一个条件, 比如responsibleId=>1
        ExtraName:'',//列名
        ExtraValue:'',//列值
        //-------------
        value: '', //初始值
        nextSelector: '',//下一个元素选择器
    };
}(jQuery);
