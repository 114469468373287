/**
 * Created by Lenovo on 2017/1/18.
 */

+function ($) {
    $.fn.echarts = function (options) {
        var opts = $.extend({},$.fn.echarts.default,options);
        return this.each(function () {
            var _this = $(this);
            echarts.init(_this[0]).setOption(opts)
        });
    };
    $.fn.echarts.default = {};
}(jQuery);
