/**
 * Created by Lenovo on 2017/1/20.
 */
+function ($) {
    'use strict';

    $.fn.selectChain = function (options) {

        var opts = $.extend({},$.fn.selectChain.default,options);

        return this.each(function () {

            var _this = $(this);

            _this.on('clean', function () {
                _this.empty();
                if(opts.prompt)
                    _this.append($("<option></option>").val('0').text(opts.prompt));
            });

            _this.on('update',function () {
                $.getJSON(opts.url,_this.data(),function (data) {
                    _this.trigger('clean');
                    $.each(data,function(i,item){
                        if(opts.value == item.id){
                            _this.append($("<option selected></option>").val(item.id).text(item.text));
                        }else {
                            _this.append($("<option></option>").val(item.id).text(item.text));
                        }
                    });
                    _this.trigger('change');
                });
            });
            _this.on('change',function () {
                var next = $(opts.nextSelector);
                if (next) {
                    next.data(_this.attr('id'),_this.val());
                    next.trigger('update');
                }
                var sync = $(opts.syncSelector);
                if (sync) {
                    if (opts.syncAttr == 'value') {
                        sync.val(_this.find('option:selected').val());
                    } else if (opts.syncAttr == 'text') {
                        sync.val(_this.find('option:selected').text());
                    }
                }
            });

            if (opts.init){
                _this.trigger('update');
            }
        })
    };

    $.fn.selectChain.default = {
        init:false, //是否初始化
        prompt:'', //提示
        value:'', //初始值
        url:'', //数据获取地址
        nextSelector:'',//下一个元素选择器
        syncSelector:'', //当自己变化后，同步更新另外一个表单元素 input 类型的
        syncAttr:'value' //同步的时候把那个属性同步过去
    };
}(jQuery);