/**
 * Created by Lenovo on 2017/1/18.
 */
+function ($) {

    $.fn.vis = function (options) {
        var opts = $.extend({},$.fn.vis.default,options);
        return this.each(function () {
            var _this = $(this);
            new vis.Network(_this[0],{
                nodes:new vis.DataSet(opts.nodes),
                edges:new vis.DataSet(opts.links)
            },opts.options);
        });
    };

    $.fn.vis.default = {
        nodes:[],
        links:[],
        options:{}
    };
}(jQuery);