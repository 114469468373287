/**
 * Created by Administrator on 2017/3/4.
 */


+function ($) {
    'use strict';
    $.fn.select2chain = function (options) {
        var opts = $.extend({}, $.fn.select2chain.default, options);
        return this.each(function () {
            var _this = $(this);
            //初始化的时候先把opts.ajax.data写入自身的data('select2chain')
            _this.data('select2chain',$.extend({},_this.data('select2chain'),opts.ajax.data));
            var _value = _this.find('[vid="' + opts.valId+'"]:first');
            var _name = _this.find('[nid="' + opts.namId+'"]:first');
            var _text = _this.find('span').text(opts.prompt);
            var _select = _this.find('select');
            var _list = _this.find('ul').css({
                maxHeight: opts.maxHeight,
                overflowY: 'auto'
            });
            var _searchBox = $('<li class="text-center"></li>')
                .on('clean', function (e) {
                    _list.find('li:not(:first-child)').remove();

                });
            //延迟一秒触发update事件，连续触发则清空上一次的定时任务
            // ，防止英文输入过多的发送请求
            var timeout = 1e3; // 1 second default timeout
            var timeoutReference = null;

            var cpLock = false;
            var _search = $('<input style="width:90%;" type="text" value="" />&nbsp;<i class="fa fa-search"></i>&nbsp;')
                .on('compositionstart', function () {
                    cpLock = true;
                })
                .on('compositionend', function () {
                    cpLock = false;
                    AjaxRender();
                })
                .on('input', function (e) {
                    if (!cpLock) {
                        if (timeoutReference) clearTimeout(timeoutReference);
                        timeoutReference = setTimeout(function(){
                            AjaxRender();
                        }, timeout);

                    }

                });
            _searchBox.append(_search);
            _list.append(_searchBox);
            function AjaxRender() {
                // var id = opts.fieldName;
                var term = _search.val();
                var params = $.extend({},_this.data('select2chain'),{term : term});
                $.post(
                    opts.ajax.url,
                    params,
                    function (data) {
                        var val = _value.val();
                        _searchBox.trigger('clean');

                        $.each(data.results, function (idx, elem) {
                            _list.append('<li class="divider"></li>');
                            var option = $('<li>');
                            if (elem.id == opts.value) {
                                option.addClass('active');
                                _name.val(elem.text);
                                _text.html(elem.text.trim());
                                //初始化的默认值
                                _select.empty().append('<option value="'+elem.id+'" selected></option>')
                                //改变下一个插件的值
                                var callable = opts.afterSelected;
                                if (callable){
                                    callable(elem);
                                }
                            }
                            var a = $('<a href="#">');
                            a.attr("data-val", elem.id)
                                .html(elem.text)
                                .click(function (e) {
                                    if ( typeof opts.clickabled == 'function' && !opts.clickabled(elem)) return false;
                                    e.preventDefault();
                                    var _aThis = $(e.target);
                                    _value.val(_aThis.data("val"));
                                    _name.val(_aThis.text());
                                    _text.html(_aThis.text().trim());
                                    //改变隐藏select的值
                                    var v = _aThis.data('val');
                                    _select.empty().append('<option value="'+v+'" selected></option>')
                                    _select.trigger('change');
                                    //改变下一个插件的值
                                    var callable = opts.afterSelected;
                                    if (callable){
                                        callable(elem);
                                    }


                                });
                            if (typeof opts.wrap == 'function'){
                                var wrap = opts.wrap(a,elem);
                                option.append(wrap);
                            }else
                                option.append(a);
                            _list.append(option);
                        });

                    },'json'
                );
            }
            _this.on('update', function (e) {
                AjaxRender();
            });
            _this.on('show.bs.dropdown', function () {
                var val = _value.val();
                _list.find('a').each(function () {
                    var a = $(this);
                    if (a.data('val') == val) {
                        a.parent().addClass('active');
                    } else {
                        a.parent().removeClass('active');
                    }
                });
            });

            AjaxRender();

        });
    };

    $.fn.select2chain.default = {
        fieldName: 'adminId',
        maxHeight: '300px',
        valId: '保存控件value的element Id',
        namId: '保存控件name的element Id',
        url: '',
        prompt: '请选择',

        value: '', //初始值
        nextSelector: '',//下一个元素选择器
    };
}(jQuery);
