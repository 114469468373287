/**
 * Created by Administrator on 2017/1/18.
 */

+function ($) {
    'use strict';

    $.fn.notification = function (options) {
        var opts = $.extend({}, $.fn.notification.default, options);
        var _this = $(this);

        $.ajax({
            url: opts.url, data: {limit: opts.limit}, success: function (data) {
                eval('data=' + data);
                var numLabel = $('.label-warning');
                var numLabel1 = $('.text-danger');
                var ul = $(opts.uniqid);
                if (data.total > 0)
                    numLabel.html(data.total);
                numLabel1.html(data.total);
                var items = data.data;
                for (var i in items) {
                    var text =
                        '<li class="item-li" ><a  modal-target="#notificationWidget" data-content-id="' + items[i].id +
                        '" href="' + items[i].url + '" target="_blank" >' +
                        '<i class="' + items[i].iconClass + '"></i><span class="text-bold" title="'+items[i].title+'">' + items[i].title + '</span></a></li>';
                    ul.append(text);
                }
                $('.item-li a').on('click', function () {
                    var notificationId = $(this).data('content-id');
                    $(this).find('span').removeClass('text-bold');
                    $.ajax({url: opts.updateUrl, data: {id: notificationId}})
                })
            }
        });
    };

    $.fn.notification.default = {};
}(jQuery);