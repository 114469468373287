/**
 * Created by Administrator on 2017/1/22 0022.
 */
/**
 * Created by wuyupeng@huluwa.cc on 2016/01/22.
 *
 */
+function ($) {
    'use strict';

    $.fn.datadrawling = function (options) {
        var opts = $.extend({}, $.fn.datadrawling.default, options);
        return this.each(function () {
            var _this = $(this);
            _this.on('change', function (e) {
                var $val = _this.val();
                var $par = {'url': $val,'rule':opts.rule};
                $.getJSON(opts.url, $par, function (data) {
                    $(opts.show).val(data[0]['tab']);
                });
            });
        });
    };
    $.fn.datadrawling.default = {};
}(jQuery);
